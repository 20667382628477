import axios from "axios";
import Cookies from "js-cookie";
import { logoutSuccess } from "redux/features/Auth/loginSlice";
import { mapAPIError } from "./mapAPIError";
import { toast } from "react-toastify";

export const api =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (!action?.payload?.apiName) return next(action);
    next(action);

    const successStatuses = [204, 200, 201];
    const {
      apiName,
      data,
      formData = false,
      method,
      onFail,
      onSuccess,
      url,
      signal,
    } = action.payload;
    const baseURL =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? ""
        : `${window.location.protocol}//${window.location.host}`;
    const abortController = new AbortController();

    dispatch({ payload: apiName, type: "globalLoading/start" });

    dispatch({ type: `${apiName}/${action.type}` });
    let headers = {
      "X-CSRFToken": Cookies.get("csrftoken"),
    };

    if (method !== "get" || method !== "GET") {
      axios.defaults.xsrfHeaderName = "x-csrftoken";
      axios.defaults.xsrfCookieName = "csrftoken";
      axios.defaults.withCredentials = true;
    }

    if (formData) {
      headers["Content-Type"] = "multipart/form-data";
    }
    try {
      //call the getcsrf api if csrf token not found
      if (!Cookies.get("csrftoken") && (method !== "get" || method !== "GET")) {
        await axios.request({
          baseURL,
          method: "get",
          url: "/api/csrf-token/",
        });
      }

      const response = await axios.request({
        baseURL,
        data,
        headers,
        method,
        url,
        signal: signal ? signal : abortController.signal,
      });

      if (successStatuses.includes(response.status)) {
        dispatch({
          payload: response.data,
          type: `${apiName}/${action.type}Success`,
        });
      }

      dispatch({ payload: apiName, type: "globalLoading/stop" });

      onSuccess && dispatch(onSuccess(response.data, data));
    } catch (err) {
      const error = err;
      const { message, response } = error;
      const payload = {
        message: mapAPIError(error, response?.status),
        statusCode: response?.status,
        statusText: response?.data,
      };
      const isLoggedIn = getState().auth.loggedIn;

      dispatch({
        type: `${apiName}/${action.type}Fail`,
      });

      if (payload.statusCode === 403) {
        // Abort all ongoing requests if a 403 status is encountered
        abortController.abort();
        if (isLoggedIn) {
          // Added toast behavior here as it makes more sense to show it asynchronously after the request errors than place
          // a side effect in the reducer
          toast.error("Session expires!");
          dispatch(logoutSuccess());
        }
      }

      if (payload.statusCode === 401) {
        if (isLoggedIn) {
          toast.error("Session expires!");
          dispatch(logoutSuccess());
        } else {
          dispatch(
            logoutSuccess({
              message: "Username or Password is wrong!",
              type: "error",
              actionType: "auth/loginFailed",
            }),
          );
        }

        dispatch(
          logoutSuccess(
            isLoggedIn
              ? { message: "Session expires!", type: "error" }
              : {
                  message: "Username or Password is wrong!",
                  type: "error",
                  actionType: "auth/loginFailed",
                },
          ),
        );
      } else {
        dispatch({ payload, type: `globalErrors/get` });
      }
      dispatch({ payload: apiName, type: "globalLoading/stop" });
      onFail && dispatch(onFail(message));
    }
  };
