import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import {
  clearLabsTestData,
  getLabRequestId,
  getLabTests,
  postLabRequestId,
} from "redux/features/Labs/labsSlice";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/common/Typography/Text";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Diagnosis } from "./Diagnosis";
import { Lab } from "./Lab";
import { addLabTest, deleteLabTest, updateLabTest } from "apiClients/labs.api";
import { getLabProvidersList } from "redux/features/Labs/LabProvidersSlice";
import { SmartRecommendations } from "../SmartRecommendations/SmartRecommendations";
import { CPTCode } from "./CPTCode";
import { Imaging } from "./Imaging";
import { Referral } from "./Referrals";
import { deleteMedication } from "apiClients/medications";
import { Prescription } from "./Prescription";

const EndVisitDetail = ({
  setUserLabEntries,
  encounter,
  encounterData,
  setEncounterData,
}) => {
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const [labTests, setLabTests] = useState([]);
  const { deleteLabTestSuccess, labsTestData } = useSelector(
    (state) => state.labs,
  );
  const [labProvider, setLabProvider] = useState({
    provider: "expressLabs",
    value: 1,
  });
  const [labReqId, setLabReqId] = useState(null);
  const {
    labRequestData,
    postLabTestIsAdded,
    getLabReqIsLoading,
    postLabRequestAdded,
  } = useSelector((state) => state.labs);
  const { labProvidersDataOptions } = useSelector(
    (state) => state.labProviders,
  );

  const { userId } = useSelector((state) => state.auth);
  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          return deleteLabTest(patientId, dirtyValue.id);
        } else {
          return updateLabTest(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        return addLabTest(patientId, dirtyValue);
      }
    },
    onSuccess: () => {
      // call get api for the labs tests
      dispatch(getLabTests(patientId, encounter?.id));
    },
  });

  useEffect(() => {
    // Request lab id only if it is not present for the patient and encounter id
    const filterLabReqByEncAndPatient = labRequestData?.find(
      (v) => v?.lab_provider_id === 1,
    );

    if (!filterLabReqByEncAndPatient?.id && !getLabReqIsLoading) {
      if (labProvider?.value && patientId) {
        dispatch(
          postLabRequestId(patientId, {
            encounter_id: encounter?.id,
            user_id: userId,
            lab_provider_id: 1,
          }),
        );
      }
    } else {
      setLabReqId(filterLabReqByEncAndPatient?.id);
    }
  }, [labProvider, patientId, getLabReqIsLoading, labRequestData?.length]);

  useEffect(() => {
    if (postLabTestIsAdded) {
      dispatch(clearLabsTestData());
      setLabProvider({});
    }
  }, [postLabTestIsAdded]);

  useEffect(() => {
    const filterLabReqByEncAndPatient = labRequestData?.find(
      (v) => v?.lab_provider_id === labProvider?.value,
    );
    const findLabProvider = labProvidersDataOptions?.find(
      (v) => v?.value === filterLabReqByEncAndPatient?.lab_provider_id,
    );

    if (findLabProvider?.value) {
      setLabProvider(findLabProvider);
    }
  }, [labRequestData?.length, labProvidersDataOptions?.length]);

  useEffect(() => {
    return () => {
      dispatch(clearLabsTestData());
      setLabProvider({});
    };
  }, []);

  const onSelectLabProvider = (_, v) => {
    if (v?.value) {
      setLabProvider(v);
    } else {
      setLabProvider({});
    }
  };

  useEffect(() => {
    if (labProvidersDataOptions?.length) {
      onSelectLabProvider("", labProvidersDataOptions[0]);
    }
  }, [labProvidersDataOptions]);

  // Load lab providers list on component mount.
  useEffect(() => {
    dispatch(getLabProvidersList());
    if (patientId && encounter?.id) {
      dispatch(getLabRequestId(patientId, encounter?.id));
    }
  }, [patientId, encounter?.id]);

  useEffect(() => {
    postLabRequestAdded && dispatch(getLabRequestId(patientId, encounter?.id));
  }, [postLabRequestAdded]);

  const deleteLab = (labId) => {
    setUserLabEntries((prevEntries) =>
      prevEntries.filter((entry) => entry.id !== labId),
    );
    setLabTests((prevTests) => prevTests.filter((test) => test.id !== labId));
  };

  useEffect(() => {
    setLabTests((prevState) => {
      if (labsTestData?.length) {
        const newLabs = labsTestData
          .filter(
            (lab) => !prevState.some((existing) => existing.id === lab.id),
          )
          .map((lab) => ({
            ...lab,
            lab_code: lab.code,
            lab_name: lab.name,
            aiGenerated: false,
          }));

        return [...prevState, ...newLabs];
      }
      return prevState;
    });
  }, [labsTestData]);

  useEffect(() => {
    labsTestData && setUserLabEntries(labsTestData);
  }, [labsTestData]);

  const handleDeleteSuggestion = (key, itemToRemove) => {
    setEncounterData((prevData) => {
      // Create a shallow copy of prevData
      let updatedData = { ...prevData };

      // Create a shallow copy of smart_note_data
      updatedData.smart_note_data = { ...prevData.smart_note_data };

      // Handling for "medications" (Array of Strings)
      if (key === "medications") {
        updatedData.smart_note_data[key] = prevData.smart_note_data[key].filter(
          (medication) => medication !== itemToRemove,
        );
      }

      // Handling for "labs_to_order" (Array of Objects)
      else if (key === "labs_to_order") {
        updatedData.smart_note_data[key] = prevData.smart_note_data[key].filter(
          (lab) =>
            lab.lab_name !== itemToRemove.lab_name ||
            lab.icd_10_code !== itemToRemove.icd_10_code,
        );
      }

      // Handling for "diagnosis_data" (Nested Object with Categories)
      else if (key === "diagnosis_data") {
        let category = itemToRemove.category;

        // Create a deep copy of the diagnosis_data object
        updatedData.smart_note_data.diagnosis_data = {
          ...prevData.smart_note_data.diagnosis_data,
        };

        // Ensure category exists before modifying
        if (category in updatedData.smart_note_data.diagnosis_data) {
          updatedData.smart_note_data.diagnosis_data[category] =
            updatedData.smart_note_data.diagnosis_data[category].filter(
              (diagnosis) => diagnosis.condition !== itemToRemove.condition,
            );
        }
      } else if (key === "cpt_codes") {
        if (typeof prevData.smart_note_data.cpt_codes === "string") {
          // Convert string to array and remove the item if it matches
          updatedData.smart_note_data.cpt_codes =
            prevData.smart_note_data.cpt_codes === itemToRemove
              ? ""
              : prevData.smart_note_data.cpt_codes;
        } else {
          // If it's already an array, filter out the item to remove
          updatedData.smart_note_data.cpt_codes =
            prevData.smart_note_data.cpt_codes.filter(
              (code) => code.cpt_code !== itemToRemove.cpt_code,
            );
        }
      }
      return updatedData; // Update state correctly
    });
  };

  // Function to add a new imaging object to the state
  function addImaging(newImaging) {
    setEncounterData((prevState) => ({
      ...prevState,
      smart_note_data: {
        ...prevState.smart_note_data,
        imaging: [...(prevState.smart_note_data.imaging || []), newImaging],
      },
    }));
  }

  // Function to remove an imaging object from the state based on loinc_code
  function removeImaging(loincCode) {
    setEncounterData((prevState) => ({
      ...prevState,
      smart_note_data: {
        ...prevState.smart_note_data,
        imaging: prevState.smart_note_data.imaging.filter(
          (imaging) => imaging.loinc_code !== loincCode,
        ),
      },
    }));
  }

  const updatePrescriptionNote = (lookupKey, newNote, newStatus) => {
    setEncounterData((prevData) => ({
      ...prevData,
      smart_note_data: {
        ...prevData.smart_note_data,
        medication_changes: prevData.smart_note_data.medication_changes.map(
          (prescription) =>
            prescription.medication === lookupKey ||
            prescription.drug_name === lookupKey
              ? {
                  ...prescription,
                  note: newNote !== null ? newNote : prescription.note,
                  status: newStatus !== null ? newStatus : prescription.status,
                }
              : prescription,
        ),
      },
    }));
  };

  const deletePrescription = (perscriptionID) => {
    setEncounterData((prevData) => ({
      ...prevData,
      smart_note_data: {
        ...prevData.smart_note_data,
        medication_changes: prevData.smart_note_data.medication_changes.filter(
          (prescription) => prescription.id !== perscriptionID,
        ),
      },
    }));
    return deleteMedication(patientId, perscriptionID);
  };

  const updateImagingNote = (actionToMatch, newNote, newStatus) => {
    setEncounterData((prevData) => ({
      ...prevData,
      smart_note_data: {
        ...prevData.smart_note_data,
        imaging: prevData.smart_note_data.imaging.map((imaging) =>
          imaging.action === actionToMatch
            ? {
                ...imaging,
                note: newNote !== null ? newNote : imaging.note,
                status: newStatus !== null ? newStatus : imaging.status,
              }
            : imaging,
        ),
      },
    }));
  };

  function addReferral(newReferral) {
    setEncounterData((prevState) => ({
      ...prevState,
      smart_note_data: {
        ...prevState.smart_note_data,
        referrals: [
          ...(prevState.smart_note_data.referrals || []),
          newReferral,
        ],
      },
    }));
  }

  // Function to remove a referral object from the state based on loinc_code
  function removeReferral(loincCode) {
    setEncounterData((prevState) => ({
      ...prevState,
      smart_note_data: {
        ...prevState.smart_note_data,
        referrals: prevState.smart_note_data.referrals.filter(
          (referral) => referral.loinc_code !== loincCode,
        ),
      },
    }));
  }

  const updateReferralOrProcedureNote = (actionToMatch, newNote, newStatus) => {
    setEncounterData((prevData) => ({
      ...prevData,
      smart_note_data: {
        ...prevData.smart_note_data,
        referrals: prevData.smart_note_data.referrals?.map((referral) =>
          referral.action === actionToMatch
            ? {
                ...referral,
                note: newNote !== null ? newNote : referral.note,
                status: newStatus !== null ? newStatus : referral.status,
              }
            : referral,
        ),
        procedures: prevData.smart_note_data.procedures?.map((procedure) =>
          procedure.action === actionToMatch
            ? { ...procedure, note: newNote }
            : procedure,
        ),
      },
    }));
  };

  const updateLabToOrder = (lab_name, status = "", note = "") => {
    setEncounterData((prevData) => ({
      ...prevData,
      smart_note_data: {
        ...prevData.smart_note_data,
        labs_to_order: prevData.smart_note_data.labs_to_order.map((lto) =>
          lto.lab_name === lab_name
            ? {
                ...lto,
                status: status !== "" ? status : lto.status,
                note: note !== "" ? note : lto.note,
              }
            : lto,
        ),
      },
    }));
  };

  const addCptNIcds = (isIcd = false, icdCptData) => {
    if (isIcd) {
      const newDiagnosis = {
        plan: [icdCptData?.plan],
        status: icdCptData?.status ?? "Unknown", // default status; you can change this as needed
        category: icdCptData?.category ?? "other",
        condition: icdCptData?.condition,
        icd_10_code: icdCptData?.icd_10_code,
        recommendations: [],
        pertinent_findings: [],
        recommendations_v2: [],
        status_pertinent_findings: [],
        manually_added: true,
      };
      setEncounterData((prevData) => {
        let updatedData = {
          ...prevData,
          smart_note_data: {
            ...prevData.smart_note_data,
            diagnosis_data: {
              ...prevData.smart_note_data.diagnosis_data,
            },
          },
        };

        if (updatedData.smart_note_data.diagnosis_data.other) {
          // Create a new array to avoid mutating the original array
          updatedData.smart_note_data.diagnosis_data.other = [
            ...updatedData.smart_note_data.diagnosis_data.other,
            newDiagnosis,
          ];
        } else {
          updatedData.smart_note_data.diagnosis_data.other = [newDiagnosis];
        }

        return updatedData;
      });
    } else {
      setEncounterData((prevData) => {
        let updatedData = {
          ...prevData,
          smart_note_data: {
            ...prevData.smart_note_data,
          },
        };

        // Check if cpt_codes is a string
        if (typeof updatedData.smart_note_data.cpt_codes === "string") {
          const wrappedExistingCPTCode = {
            cpt_code: updatedData.smart_note_data.cpt_codes,
            aiGenerated: true,
          };

          // Convert string to array and add the new CPT code
          updatedData.smart_note_data.cpt_codes = [
            wrappedExistingCPTCode,
            icdCptData,
          ];
        } else {
          // If it's already an array, push the new CPT code into the array
          updatedData.smart_note_data.cpt_codes = [
            ...updatedData.smart_note_data.cpt_codes,
            icdCptData,
          ];
        }

        return updatedData;
      });
    }
  };

  const editCPTCodes = (updatedCptCode) => {
    setEncounterData((prevData) => {
      let updatedData = {
        ...prevData,
        smart_note_data: {
          ...prevData.smart_note_data,
          cpt_codes: prevData.smart_note_data.cpt_codes,
        },
      };

      // When there is only one CPT code it comes back as a string
      if (typeof updatedData.smart_note_data.cpt_codes === "string") {
        updatedData.smart_note_data.cpt_codes = [updatedCptCode];
      }

      // Otherwise, we are working with more than one code
      updatedData.smart_note_data.cpt_codes =
        updatedData.smart_note_data.cpt_codes.map((cpt) => {
          if (cpt.cpt_code === updatedCptCode.cpt_code) {
            return updatedCptCode;
          }
          return cpt;
        });
      return updatedData;
    });
  };

  const editPrescriptions = (updatedPrescription) => {
    setEncounterData((prevData) => {
      let updatedData = {
        ...prevData,
        smart_note_data: {
          ...prevData.smart_note_data,
          medication_changes: prevData.smart_note_data.medication_changes,
        },
      };
      updatedData.smart_note_data.medication_changes =
        updatedData.smart_note_data.medication_changes.map((prescription) => {
          if (
            prescription.medication === updatedPrescription.medication ||
            prescription.drug_name === updatedPrescription.medication
          ) {
            return updatedPrescription;
          }
          return prescription;
        });
      return updatedData;
    });
  };

  const addPrescription = (newPrescription, medicationLookupKey) => {
    setEncounterData((prevData) => {
      let updatedData = {
        ...prevData,
        smart_note_data: {
          ...prevData.smart_note_data,
          medication_changes: [
            ...(prevData.smart_note_data.medication_changes || []),
          ],
        },
      };
      if (medicationLookupKey) {
        updatedData.smart_note_data.medication_changes =
          updatedData.smart_note_data.medication_changes.map(
            (existingPrescription) => {
              if (
                existingPrescription.medication === medicationLookupKey ||
                existingPrescription.drug_name === medicationLookupKey
              ) {
                return newPrescription;
              }
              return existingPrescription;
            },
          );
      } else {
        updatedData.smart_note_data.medication_changes.push(newPrescription);
      }
      return updatedData;
    });
  };

  const editDiagnoses = (
    updatedDiagnosis,
    replaceByIndex = false,
    toBeRemoved = {},
  ) => {
    setEncounterData((prevData) => {
      let updatedData = {
        ...prevData,
        smart_note_data: {
          ...prevData.smart_note_data,
          diagnosis_data: {
            ...prevData.smart_note_data.diagnosis_data,
          },
        },
      };

      if (replaceByIndex) {
        // Remove the diagnosis from the 'toBeRemoved' category based on its icd_10_code
        const fromCategory = toBeRemoved.category;
        updatedData.smart_note_data.diagnosis_data[fromCategory] =
          updatedData.smart_note_data.diagnosis_data[fromCategory].filter(
            (diagnosis) => diagnosis.icd_10_code !== toBeRemoved.icd_10_code,
          );

        // Add the updatedDiagnosis to its category (updatedDiagnosis.category)
        const updatedDiagnosisCategory = updatedDiagnosis.category;
        const diagnosesArray =
          updatedData.smart_note_data.diagnosis_data[
            updatedDiagnosisCategory
          ] || [];

        const existingIndex = diagnosesArray.findIndex(
          (diagnosis) => diagnosis.icd_10_code === updatedDiagnosis.icd_10_code,
        );

        if (existingIndex !== -1) {
          // Replace the existing diagnosis
          diagnosesArray[existingIndex] = updatedDiagnosis;
        } else {
          // Add the updatedDiagnosis if not found
          diagnosesArray.push(updatedDiagnosis);
        }

        updatedData.smart_note_data.diagnosis_data[updatedDiagnosisCategory] =
          diagnosesArray;
      } else {
        // Original behavior: update the existing diagnosis in its category
        const updatedDiagnosisCategory = updatedDiagnosis.category;
        updatedData.smart_note_data.diagnosis_data[updatedDiagnosisCategory] =
          updatedData.smart_note_data.diagnosis_data[
            updatedDiagnosisCategory
          ].map((diagnosis) => {
            if (diagnosis.icd_10_code === updatedDiagnosis.icd_10_code) {
              return updatedDiagnosis;
            }
            return diagnosis;
          });
      }

      return updatedData;
    });
  };

  useEffect(() => {
    deleteLabTestSuccess && dispatch(clearLabsTestData());
  }, [deleteLabTestSuccess]);

  return (
    <Box
      sx={{
        height: "60vh",
        maxHeight: "59vh",
        overflowY: "auto",
      }}>
      <Grid container spacing={12}>
        <Grid item xs={6}>
          <Box marginY={2}>
            <Text variant="h3" fontWeight={700}>
              RECOMMENDATIONS
            </Text>
            <SmartRecommendations
              resolved={
                encounterData?.smart_note_data?.resolved_smart_recommendations
              }
              sx={{ margin: "12px 0 0 0" }}
              childSx={{ marginBottom: "18px" }}
              loading={false}
              isEndVisitView={true}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <>
            <Box marginY={2}>
              <DiagnosisSection
                title={"DIAGNOSIS"}
                onAdd={addCptNIcds}
                onEdit={editDiagnoses}
                data={encounterData?.smart_note_data?.diagnosis_data}
                onDelete={handleDeleteSuggestion}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}>
              <CPTCodesSection
                title={"CPT CODES"}
                onAdd={addCptNIcds}
                onEdit={editCPTCodes}
                data={encounterData?.smart_note_data?.cpt_codes}
                onDelete={handleDeleteSuggestion}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}>
              <LabsSection
                title={"LABS"}
                mutation={mutation}
                labReqId={labReqId}
                onDelete={deleteLab}
                onEdit={updateLabToOrder}
                data={[
                  ...(encounterData?.smart_note_data?.labs_to_order || []),
                  ...(labTests || []),
                ]}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}>
              <ImagingSection
                title={"IMAGING"}
                onAdd={addImaging}
                data={encounterData?.smart_note_data?.imaging || []}
                editNote={updateImagingNote}
                onDelete={removeImaging}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}>
              <ReferralSection
                title={"REFERRALS"}
                onAdd={addReferral}
                data={[
                  ...(encounterData?.smart_note_data?.referrals || []),
                  ...(encounterData?.smart_note_data?.procedures || []),
                ]}
                editNote={updateReferralOrProcedureNote}
                onDelete={removeReferral}
              />
            </Box>
            <Box
              sx={{
                marginY: 2,
              }}>
              <PrescriptionSection
                title={"PRESCRIPTIONS"}
                editNote={updatePrescriptionNote}
                onAdd={addPrescription}
                data={encounterData?.smart_note_data?.medication_changes}
                onDelete={deletePrescription}
                onEdit={editPrescriptions}
              />
            </Box>
          </>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EndVisitDetail;

const PrescriptionSection = ({
  title,
  data: suggestedPrescriptions = [],
  editNote,
  onDelete,
  onAdd,
}) => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [showInput, setShowInput] = useState(false);

  const allPrescriptions = [
    ...suggestedPrescriptions
      ?.filter((prescription) => {
        return prescription.action === "ADD";
      })
      ?.map((prescription) => ({
        ...prescription,
        aiGenerated: true,
      }))
      ?.filter(
        (prescription) =>
          !prescriptions.some((p) => p.medication === prescription.medication),
      ),
    ...prescriptions.map((prescription) => ({
      ...prescription,
    })),
  ];

  return (
    <Box>
      <Text variant="h3" fontWeight={700} sx={{ marginBottom: "8px" }}>
        {title}
      </Text>
      {allPrescriptions.map((prescription, index) => (
        <Prescription
          prescription={prescription}
          prescriptions={allPrescriptions}
          setPrescriptions={setPrescriptions}
          onAdd={onAdd}
          key={index}
          onDelete={onDelete}
          editNote={editNote}
          sx={{ marginTop: index === 0 ? "0px" : "16px" }}
        />
      ))}
      {showInput ? (
        <Prescription
          createNewPrescription={true}
          onAdd={onAdd}
          prescriptions={allPrescriptions}
          setPrescriptions={setPrescriptions}
          setShowInput={setShowInput}
        />
      ) : (
        <Box>
          <Button
            sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
            variant="text"
            text={"+ Add"}
            onClick={() => setShowInput(true)}
          />
        </Box>
      )}
    </Box>
  );
};

const DiagnosisSection = ({
  title,
  onAdd,
  onEdit,
  data: suggestedIcds = [],
  onDelete,
}) => {
  const [diagnoses, setDiagnoses] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const allDiagnoses = [
    ...Object.entries(suggestedIcds)
      .flatMap(([_, diagnoses]) =>
        diagnoses.map((diagnosis) => ({
          ...diagnosis,
          aiGenerated: true,
        })),
      )
      .filter(
        (diagnosis) =>
          !diagnoses.some((d) => d.icd_10_code === diagnosis.icd_10_code),
      ),
    ...diagnoses.map((diagnosis) => ({
      ...diagnosis,
    })),
  ];

  return (
    <Box marginY={1}>
      <Text variant="h3" fontWeight={700} sx={{ marginBottom: "8px" }}>
        {title}
      </Text>
      {allDiagnoses
        .filter((diagnosis) => diagnosis !== undefined)
        .map((diagnosis, index) => (
          <Diagnosis
            aiGenerated={diagnosis.aiGenerated}
            setDiagnoses={setDiagnoses}
            diagnoses={allDiagnoses}
            key={index}
            diagnosis={diagnosis}
            onEdit={onEdit}
            onDelete={onDelete}
            onAdd={onAdd}
            index={index}
            sx={{ marginTop: index === 0 ? "0px" : "16px" }}
          />
        ))}
      {showInput ? (
        <Diagnosis
          createNewDiagnosis={true}
          onAdd={onAdd}
          diagnoses={allDiagnoses}
          setDiagnoses={setDiagnoses}
          setShowInput={setShowInput}
        />
      ) : (
        <Box>
          <Button
            sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
            variant="text"
            text={"+ Add"}
            onClick={() => setShowInput(true)}
          />
        </Box>
      )}
    </Box>
  );
};

const LabsSection = ({
  title,
  data: suggestedLabs = [],
  onDelete,
  mutation,
  onEdit,
  labReqId,
}) => {
  const [labs, setLabs] = useState([]);
  const [allLabs, setAllLabs] = useState([]);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    // Get the lab_names from labs where 'deleted' property exists
    const labsToRemove = labs
      .filter((lab) => lab.hasOwnProperty("deleted"))
      .map((lab) => lab.lab_name);

    setAllLabs([
      // Process suggestedLabs: remove labs that are in the labsToRemove list.
      ...suggestedLabs
        .filter((lab) => !labsToRemove.includes(lab.lab_name))
        .map((lab) => ({
          ...lab,
          aiGenerated: lab.aiGenerated ?? true,
        })),
      // Process labs: remove labs already in suggestedLabs and those with 'deleted'
      ...labs.filter(
        (lab) =>
          !suggestedLabs.some(
            (suggestedLab) => suggestedLab.lab_name === lab.lab_name,
          ) && !labsToRemove.includes(lab.lab_name),
      ),
    ]);
  }, [suggestedLabs, labs]);

  return (
    <Box marginY={1}>
      <Text variant="h3" fontWeight={700} sx={{ marginBottom: "8px" }}>
        {title}
      </Text>
      {allLabs.map((lab, index) => {
        if (lab.deleted) return null;
        return (
          <Lab
            aiGenerated={lab.aiGenerated}
            setLabs={setLabs}
            labs={allLabs}
            key={index}
            lab={lab}
            onDelete={onDelete}
            mutation={mutation}
            labReqId={labReqId}
            onEdit={onEdit}
            sx={{ marginTop: index === 0 ? "0px" : "16px" }}
          />
        );
      })}
      {showInput ? (
        <Lab
          createNewLab={true}
          labs={allLabs}
          labReqId={labReqId}
          setLabs={setLabs}
          mutation={mutation}
          setShowInput={setShowInput}
        />
      ) : (
        <Box>
          <Button
            sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
            variant="text"
            text={"+ Add"}
            onClick={() => setShowInput(true)}
          />
        </Box>
      )}
    </Box>
  );
};

const CPTCodesSection = ({
  title,
  onAdd,
  onEdit,
  data: suggestedCPTs = [],
  onDelete,
}) => {
  const [cpts, setCpts] = useState([]);
  const [showInput, setShowInput] = useState(false);
  // suggestedCPTs -> come in as a string if there is only one generated.
  if (typeof suggestedCPTs === "string") {
    suggestedCPTs = [
      {
        cpt_code: suggestedCPTs,
        aiGenerated: true,
      },
    ];
  }

  const allCPTs = [
    ...suggestedCPTs.filter(
      (cpt) => !cpts.some((c) => c.cpt_code === cpt.cpt_code),
    ),
    ...cpts.map((cpt) => ({
      ...cpt,
      status:
        cpt.status ||
        suggestedCPTs.find((s) => s.cpt_code === cpt.cpt_code)?.status,
    })),
  ];

  return (
    <Box marginY={1}>
      <Text variant="h3" fontWeight={700} sx={{ marginBottom: "8px" }}>
        {title}
      </Text>
      {allCPTs.map((cpt, index) => (
        <CPTCode
          aiGenerated={cpt.aiGenerated}
          setCpts={setCpts}
          cptCodes={allCPTs}
          key={index}
          cptCode={cpt}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          sx={{ marginTop: index === 0 ? "0px" : "16px" }}
        />
      ))}
      {showInput ? (
        <CPTCode
          createNewCPT={true}
          onAdd={onAdd}
          cptCodes={allCPTs}
          setCpts={setCpts}
          setShowInput={setShowInput}
        />
      ) : (
        <Box>
          <Button
            sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
            variant="text"
            text={"+ Add"}
            onClick={() => setShowInput(true)}
          />
        </Box>
      )}
    </Box>
  );
};

const ImagingSection = ({
  title,
  onAdd,
  onEdit,
  data: suggestedImagings = [],
  onDelete,
  editNote,
}) => {
  const [imagings, setImagings] = useState([]);
  const [showInput, setShowInput] = useState(false);

  const allImagings = [
    ...suggestedImagings
      ?.map((imaging) => ({
        ...imaging,
        aiGenerated: true,
        loinc_code: imaging.action ? imaging.action : imaging.loinc_code,
      }))
      .filter(
        (imaging) =>
          !imagings.some(
            (d) =>
              d.loinc_code === imaging.loinc_code ||
              d.action === imaging.action,
          ),
      ),
    ...imagings,
  ];

  return (
    <Box marginY={1}>
      <Text variant="h3" fontWeight={700} sx={{ marginBottom: "8px" }}>
        {title}
      </Text>
      {allImagings.map((imaging, index) => (
        <Imaging
          aiGenerated={imaging.aiGenerated}
          setImagings={setImagings}
          imagings={allImagings}
          key={index}
          imaging={imaging}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          editNote={editNote}
          sx={{ marginTop: index === 0 ? "0px" : "16px" }}
        />
      ))}
      {showInput ? (
        <Imaging
          onAdd={onAdd}
          imagings={allImagings}
          createNewImaging={true}
          setImagings={setImagings}
          setShowInput={setShowInput}
        />
      ) : (
        <Box>
          <Button
            sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
            variant="text"
            text={"+ Add"}
            onClick={() => setShowInput(true)}
          />
        </Box>
      )}
    </Box>
  );
};

const ReferralSection = ({
  title,
  onAdd,
  onEdit,
  data: suggestedReferrals = [],
  onDelete,
  editNote,
}) => {
  const [referrals, setReferrals] = useState([]);
  const [showInput, setShowInput] = useState(false);

  const allReferrals = [
    ...suggestedReferrals
      ?.map((referral) => ({
        ...referral,
        aiGenerated: true,
        loinc_code: referral.action ? referral.action : referral?.loinc_code,
      }))
      .filter(
        (referral) =>
          !referrals.some(
            (d) =>
              d.loinc_code === referral.loinc_code ||
              d.action === referral.action,
          ),
      ),
    ...referrals,
  ];

  return (
    <Box marginY={1}>
      <Text variant="h3" fontWeight={700} sx={{ marginBottom: "8px" }}>
        {title}
      </Text>
      {allReferrals.map((referral, index) => (
        <Referral
          aiGenerated={referral.aiGenerated}
          setReferrals={setReferrals}
          referrals={allReferrals}
          key={index}
          referral={referral}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          editNote={editNote}
          sx={{ marginTop: index === 0 ? "0px" : "16px" }}
        />
      ))}
      {showInput ? (
        <Referral
          onAdd={onAdd}
          referrals={allReferrals}
          createNewReferral={true}
          setReferrals={setReferrals}
          setShowInput={setShowInput}
        />
      ) : (
        <Box>
          <Button
            sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
            variant="text"
            text={"+ Add"}
            onClick={() => setShowInput(true)}
          />
        </Box>
      )}
    </Box>
  );
};
