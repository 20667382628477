import { Box, useTheme } from "@mui/material";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import FlagOutlined from "@mui/icons-material/FlagOutlined";
import InputField from "components/common/FormComponents/InputField";
import CustomModal from "components/common/Modal/CustomModal";
import Button from "components/common/Button/Button";
import { useParams } from "react-router-dom";
import { useFeedback } from "hooks/useFeedback";
import { Refresh } from "components/common/SVG/Refresh";
import { IconButton } from "components/common/Button/IconButton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchPatient, refreshSmartSummary } from "apiClients/patients";
import { SmartSummaryLoader } from "./common/SmartSummaryLoader";
import { makeStyles } from "@mui/styles";
import { fetchPatientDocumentsSinceLastEncounter } from "apiClients/documents.api";
import { SmartSummary } from "./SmartSummary";

const useStyles = makeStyles(() => ({
  loader: {
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" },
  },
}));

export const HealthSummary = ({
  updateSmartSummaryOnIntakeChange,
  setUpdateSmartSummaryOnIntakeChange,
  smartSummaryfeedback,
  setsmartSummaryFeedback,
  smartDataRefreshing,
  setSmartDataRefreshing,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { patientId } = useParams();
  const { userId } = useSelector((state) => state.auth);
  const [refetchInterval, setRefetchInterval] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackNote, setFeedbackNote] = useState("");
  const { encounter } = useSelector((state) => state.encounter);
  const { mutationFeedback } = useFeedback(patientId, encounter?.id);

  const { mutateAsync: refresh } = useMutation({
    mutationFn: () => {
      return refreshSmartSummary(patientId);
    },
  });

  const { data: documentsSinceLastEncounter } = useQuery({
    queryKey: ["documentsSinceLastEncounter", patientId],
    queryFn: () => fetchPatientDocumentsSinceLastEncounter(patientId),
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["patientData", patientId],
      });
      queryClient.removeQueries({
        queryKey: ["documentsSinceLastEncounter", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data: patientData, refetch } = useQuery({
    queryKey: ["patientData", patientId],
    queryFn: () => fetchPatient(patientId),
    refetchInterval,
  });

  const shouldRefreshSmartSummary = () => {
    if (updateSmartSummaryOnIntakeChange) {
      return true;
    }

    return false;
  };

  const handleFeedbackStatus = (feedback) => {
    setFeedbackNote("");
    setShowFeedbackModal(false);
    setsmartSummaryFeedback(feedback);
    const payload = {
      feedback: feedbackNote ?? "",
      item_for_feedback: "smart.summary",
      thumbs_up: feedback,
      user_id: userId,
      item_id: patientId ?? "",
    };
    mutationFeedback.mutateAsync(payload);
  };

  useEffect(() => {
    // we only have feedback text for thumbs down
    // if thumbs up is true, we don't have feedback text
    if (!smartSummaryfeedback?.thumbs_up)
      setFeedbackNote(smartSummaryfeedback?.feedback);
  }, [smartSummaryfeedback]);

  // Set last updated time before refresh is triggered
  // Trigger a refresh of the smart summary
  // Set refetch interval to 5 seconds after refresh is triggered
  const onRefresh = async () => {
    setLastUpdated(
      patientData?.smart_summary_last_updated ?? new Date().toISOString(),
    );

    await refresh();
    setRefetchInterval(1000);
    setSmartDataRefreshing(true);
    setUpdateSmartSummaryOnIntakeChange(false);
  };

  // Once the new patient data has a last updated time greater than the last updated time, we stop the refetch interval.
  useEffect(() => {
    if (!patientData || !lastUpdated) return;

    if (patientData.smart_summary_last_updated > lastUpdated) {
      setLastUpdated(null);
      setRefetchInterval(0);
      setSmartDataRefreshing(false);
    }
  }, [patientData, lastUpdated]);

  useEffect(() => {
    if (!patientData || smartDataRefreshing) return;
    if (shouldRefreshSmartSummary()) {
      onRefresh();
    }
  }, [patientData, updateSmartSummaryOnIntakeChange]);

  const showSkeleton = (patientData, documentsSinceLastEncounter) => {
    return patientData && typeof documentsSinceLastEncounter === "object";
  };

  if (smartDataRefreshing) {
    return (
      <Box
        height="80vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center">
        <SmartSummaryLoader
          text="Regenerating Smart Summary"
          lowerText="Estimated Time: 60 Sec."
        />
      </Box>
    );
  }

  const boxStyle = (patientData, documentsSinceLastEncounter) => {
    if (!showSkeleton(patientData, documentsSinceLastEncounter)) {
      return { padding: "24px" };
    }
    return {
      maxHeight: "58vh",
    };
  };

  return (
    <>
      <Box
        data-testid="scd-health-patient-summary"
        sx={boxStyle(patientData, documentsSinceLastEncounter)}>
        <SkeletonWrapper
          multipleCount={4}
          id={"skeleton-smart-summary"}
          condition={showSkeleton(patientData, documentsSinceLastEncounter)}
          props={[SkeletonPropsStyleGenerator("100%", "100px")]}>
          <Box
            display="flex"
            justifyContent={"flex-end"}
            sx={{ padding: "24px 24px 0 24px" }}>
            <Box
              title={
                refetchInterval > 0
                  ? "Refreshing Smart Summary"
                  : "Refresh Smart Summary"
              }
              className={refetchInterval > 0 ? classes.loader : ""}>
              <IconButton
                disabled={refetchInterval > 0}
                variant="text"
                width="20px"
                height="20px"
                onClick={() => {
                  setSmartDataRefreshing(true);
                  onRefresh();
                }}>
                <Refresh
                  fill={
                    refetchInterval > 0
                      ? theme.palette.common.grey
                      : theme.palette.common.blue
                  }
                />
              </IconButton>
            </Box>
            <IconButton
              variant="text"
              onClick={() => setShowFeedbackModal(true)}
              width="24px"
              height="24px"
              sx={{
                marginLeft: "8px",
                color: theme.palette.common.blue,
              }}>
              <FlagOutlined />
            </IconButton>
          </Box>
          <div className="smart-summary editor-container">
            <SmartSummary
              patientData={patientData}
              documentsSinceLastEncounter={documentsSinceLastEncounter}
            />
          </div>
        </SkeletonWrapper>
      </Box>

      <CustomModal
        fullWidth
        maxWidth="md"
        title="Smart Summary Feedback"
        open={showFeedbackModal}
        setOpen={() => {
          handleFeedbackStatus(false);
        }}
        actions={[
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "4px",
              gap: 2,
            }}>
            <Button
              text={"Cancel"}
              onClick={() => handleFeedbackStatus(false)}
              variant="outlined"
              sx={{ marginRight: "10px", width: "150px" }}
            />
            <Button
              onClick={() => handleFeedbackStatus(false)}
              text={"Save"}
              sx={{ width: "150px" }}
            />
          </Box>,
        ]}>
        <Box paddingY={0.5} paddingX={1.1}>
          <Text variant="h3" marginY={1}>
            Feedback
          </Text>
          <InputField
            rows={4}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            flex={1}
            multiline
            name={"scd-assesment-notes"}
            labelVariant="formLabel"
            value={feedbackNote}
            onChange={(e) => setFeedbackNote(e.target.value)}
          />
        </Box>
      </CustomModal>
    </>
  );
};
