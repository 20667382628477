import { Box, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { Fragment, memo, useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useNavigate } from "react-router-dom";
import { flexColumn, flexRowCenterCenter, justifyContentCenter } from "styles";
import {
  cardButtonStyling,
  cardDateTimeStatusStyling,
  cardNameDiseaseImageBoxStyling,
  cardReasonWrapperStyling,
  cardResponsiveStyling,
  cardVisitReasonBoxStyling,
} from "styles/Dashboard/SchedulePatientsStyle";
import circleleftarrow from "../../../assets/svg/light/checkout-icon.svg";
import correct from "../../../assets/svg/colored/correct.svg";
import correctBox from "../../../assets/svg/colored/correctBox.svg";
import wrong from "../../../assets/svg/colored/wrong.svg";
import { checkNullOrUndefinedString } from "utils/helper";
import Avatar from "@mui/material/Avatar";
import { useSwiper } from "swiper/react";
import { dateFormat } from "utils/date";

const Cards = ({
  id,
  time,
  status,
  img,
  name,
  hasPatientSummary,
  disease,
  description,
  lastVisitDate,
  emrId,
  visitSummaryLoading,
  isActive,
  patientId,
  isReviewLater,
  summaryVisitId,
  setPatientId,
  appointmentId,
  index,
}) => {
  let theme = useTheme();
  let swiper = useSwiper();
  const navigate = useNavigate();
  const [statusImg, setStatusImg] = useState("");
  const [_, setFontSize] = useState(16);

  useEffect(() => {
    const handleResize = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      );
      const newFontSize = vw / 20;
      setFontSize(newFontSize);
    };

    // Initial font size calculation
    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handling checkin-status images
  useEffect(() => {
    // checking all the status in lowercase
    if (status.toLowerCase() == "checked out") {
      setStatusImg(circleleftarrow);
    } else if (status.toLowerCase() == "cancelled") {
      setStatusImg(wrong);
    } else if (status.toLowerCase() == "scheduled") {
      setStatusImg(correctBox);
    } else {
      setStatusImg(correct);
    }
  }, []);

  return (
    <Box
      id={id}
      className="main-card-body"
      sx={{
        ...cardResponsiveStyling(status, theme, isActive),
      }}
      onClick={() => {
        swiper.slideTo(index);
      }}>
      <Box
        sx={{
          flex: 1,
          [`@media  (min-height:500px) and (max-height:630px)`]: {
            flex: "unset",
          },
        }}>
        <Box
          onClick={() =>
            navigate(
              `/schedule/patient/${patientId}/appointment/${appointmentId}`,
            )
          }>
          <Box sx={cardDateTimeStatusStyling}>
            <Text classes={"card-time"} variant="h3">
              <span data-testid="card-time">
                {moment(time).format("hh:mm a")}
              </span>
            </Text>
          </Box>
          <Box
            sx={cardNameDiseaseImageBoxStyling}
            className="patient-profile-box">
            <Box style={{ ...flexColumn, ...justifyContentCenter }}>
              <Text
                variant="h2"
                fontWeight={700}
                classes="patient-name"
                color={`${isActive ? "oceanBlue" : "black"}`}
                margin="0px">
                <span style={{ textWrap: "wrap" }} data-testid={"card-name"}>
                  {checkNullOrUndefinedString(name)}
                </span>
              </Text>
              <Text classes={"card-disease"} marginY={1} variant="h4">
                <span>Patient ID: {emrId}</span>
              </Text>
              <Text
                classes={"card-disease"}
                variant="h2"
                margin="5px 0px 0px 0px">
                <span data-testid={"card-disease"}>{disease}</span>
              </Text>
            </Box>
            <Avatar variant="rounded" data-testid="patient-img"></Avatar>
          </Box>
          <Box sx={cardReasonWrapperStyling}>
            <Box sx={{ flex: "2" }}>
              <Text classes={"reason-heading"} variant="formLabel">
                <i>Reason for the visit:</i>
              </Text>
              <Box className="visit-reason-box" sx={cardVisitReasonBoxStyling}>
                <Text
                  classes={"visit-reason"}
                  variant="h3"
                  color="black"
                  letterSpacing="0.5px">
                  <span data-testid="card-reason-for-visit">{description}</span>
                </Text>
              </Box>
            </Box>
            <Box sx={{ height: { lg: "60px", md: "40px" }, display: "flex" }}>
              <Box sx={{ width: "50%" }}>
                <Box>
                  <Text
                    classes={"visit-heading"}
                    variant="formLabel"
                    padding="none">
                    <i>Last Visit:</i>
                  </Text>
                </Box>
                <Box>
                  <Text
                    classes={"visit-date"}
                    variant="h3"
                    padding="none"
                    fontWeight="600"
                    color="black">
                    <span data-testid="card-date-visit">
                      {lastVisitDate
                        ? moment(lastVisitDate).format(dateFormat)
                        : "N/A"}
                    </span>
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="button-box"
          sx={{
            height: "10%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}>
          <Button
            disabled={hasPatientSummary}
            variant="outlined"
            isLoading={patientId == summaryVisitId && visitSummaryLoading}
            styleType={isActive ? "primary" : "inactive"}
            sx={cardButtonStyling()}
            onClick={(e) => {
              e.stopPropagation();
              setPatientId(patientId);
            }}
            text={"Visit Summary"}
          />
          <Button
            id="start-patient-intake"
            styleType={isActive ? "primary" : "inactive"}
            sx={cardButtonStyling()}
            onClick={() =>
              navigate(`/patients/${patientId}`, {
                state: { from: "/appointments", intakeFlag: true },
              })
            }
            text={"Patient Intake"}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default memo(Cards);
