import { apiDelete, apiGet } from "./base";

export const fetchOpenEncounters = async ({ pageParam }) => {
  const url = "/api/encounters/?open=true";
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const searchEncounters = async ({ pageParam, params }) => {
  const url = `/api/encounters/search/?${params.toString()}`;

  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const fetchCpts = async ({ query }) => {
  const url = `/api/cpt-codes/?query=${query}&page=1&page_size=20`;
  const response = await apiGet(url);
  return response.data?.results;
};

export const fetchIcds = async ({ query }) => {
  const url = `/api/icd10-codes/?query=${query}&page=1&page_size=20`;
  const response = await apiGet(url);
  return response.data?.results;
};

export const fetchLabs = async ({ query }) => {
  const url = `/api/compendium-lab-tests/?prefix=${query}`;
  const response = await apiGet(url);
  return response.data?.results;
};

export const fetchImagingCodes = async ({ query, page }) => {
  const url = `/api/imaging-codes/?query=${query}&page=${page}&page_size=20`;
  const response = await apiGet(url);
  return response.data;
};

export const fetchReferralCodes = async ({ query, page }) => {
  const url = `/api/referral-codes/?query=${query}&page=${page}&page_size=20`;
  const response = await apiGet(url);
  return response.data;
};

export const fetchEncountersByPatient = async (patientId, { pageParam }) => {
  const url = `/api/patients/${patientId}/encounters/`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const deleteOpenEncounter = async (encounterId) => {
  const url = `/api/encounters/${encounterId}/`;
  const response = await apiDelete(url);
  return response.data;
};
