import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getApiRedirect } from "utils/helper";

export const initialAuthStateValue = {
  userId: null,
  isLoading: false,
  firstTimeLogin: false,
  isLogoutLoading: false,
  isLoadingCSRF: false,
  account_id: null,
  csrfToken: "",
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  photo: "",
  designation: "",
  phoneNumber: "",
  title: "",
  dob: "",
  medicalLicenseNumber: "",
  loggedIn: false,
  message: "",
  userWasLoggedIn: false,
  // Flag to keep the OIDC login page from testing user's login when the user is in process of logging out
};

export const authName = "auth";

export const loginSlice = createSlice({
  name: "auth",
  initialState: initialAuthStateValue,
  reducers: {
    login: (state, action) => {
      state.isLoading = true;
      state.message = "";
    },
    getAccountsSuccess: (state, action) => {
      state.account_id = action.payload?.active_account;
      state.username = action.payload?.username;
      state.userId = action.payload?.id;
      state.email = action.payload?.email;
      state.firstName = action.payload?.first_name;
      state.lastName = action.payload?.last_name;
      state.photo = action.payload?.photo;
      state.designation = action.payload?.designation;
      state.phoneNumber = action.payload?.phone_number;
      state.title = action.payload?.title;
      state.dob = action.payload?.birthdate;
      state.medicalLicenseNumber = action.payload?.medical_license_number;
    },
    getCSRF: (state, action) => {
      state.isLoadingCSRF = true;
      state.message = "";
    },
    getCSRFSuccess: (state, action) => {
      state.isLoadingCSRF = false;
      state.csrfToken = Cookies.get("csrftoken");
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.firstTimeLogin = action.payload?.first_time_login;
      state.loggedIn = true;
      state.userWasLoggedIn = true;
      state.message = action.payload?.message ?? "loggedin";
    },
    loginFail: (state, action) => {
      state.isLoading = false;
      state.isLoadingCSRF = false;
      state.loggedIn = false;
      state.message = "";
    },
    logout: (state) => {
      state.isLogoutLoading = true;
    },
    logoutSuccess: (state, action) => {
      // Only clear CSRF token, all else is cleared by the root reducer
      Cookies.remove("csrftoken");
    },
    clearUserLoggedIn: (state) => {
      state.userWasLoggedIn = false;
    },
    logoutFail: (state) => {
      state.isLogoutLoading = false;
    },
    clearLoginMessage: (state, action) => {
      Cookies.remove("csrftoken");
      state.csrfToken = "";
      state.message = "";
    },
    getCurrentUser: (state, action) => {},
    getCurrentUserSuccess: (state, action) => {
      state.isLoading = false;
      state.firstTimeLogin = action.payload?.first_login;
      state.loggedIn = true;
      state.userWasLoggedIn = true;
      state.message = action.payload?.message ?? "loggedin";
      state.account_id = action.payload?.active_account;
      state.username = action.payload?.username;
      state.userId = action.payload?.id;
      state.email = action.payload?.email;
      state.firstName = action.payload?.first_name;
      state.lastName = action.payload?.last_name;
      state.photo = action.payload?.photo;
      state.designation = action.payload?.designation;
      state.phoneNumber = action.payload?.phone_number;
      state.title = action.payload?.title;
      state.dob = action.payload?.birthdate;
      state.medicalLicenseNumber = action.payload?.medical_license_number;
    },
    getCurrentUserFail: (state, action) => {
      state.loggedIn = false;
      window.location.href = getApiRedirect("/api/oidc-login/");
    },
    initiateLogoutOidc: (state) => {
      state.userWasLoggedIn = false;
      state.isLogoutLoading = true;
      state.loggedIn = false;
    },
    logoutOidc: (state) => {
      // Only clear CSRF token, all else is cleared by the root reducer
      Cookies.remove("csrftoken");

      // Redirect to the OIDC logout endpoint
      // This happens after the session state is cleared by the root reducer,
      // hence the need for a delay before the getCurrentUser call in the
      // LoginFormOidc component.
      window.location.href = getApiRedirect("/api/oidc-logout/");
    },
  },
});

export const {
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  clearLoginMessage,
  clearUserLoggedIn,
  logoutOidc,
  initiateLogoutOidc,
} = loginSlice.actions;

export const selectUser = (state) => state.auth;

export default loginSlice.reducer;

export const onLogin = (data, remainLogin = false) => ({
  payload: {
    apiName: authName,
    data,
    onSuccess: loginSuccess,
    method: "post",
    url: "/api/api-login",
    rememberMe: remainLogin,
  },
  type: "login",
});

export const getCSRF = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/csrf-token/`,
  },
  type: "getCSRF",
});

export const getAccountID = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/users/current/`,
  },
  type: "getAccounts",
});

export const logoutApi = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/api-logout/`,
  },
  type: "logout",
});

export const getCurrentUser = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/users/current/`,
  },
  type: "getCurrentUser",
});
