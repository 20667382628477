import React from "react";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Refresh } from "components/common/SVG/Refresh";
import FlagOutlined from "@mui/icons-material/FlagOutlined";
import { IconButton } from "components/common/Button/IconButton";
import { Trash } from "components/common/SVG/Trash";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Favorite } from "components/common/SVG/Fav";

const ActionItems = ({
  onCheckmark,
  onRefuse,
  onEdit,
  onEditCancel,
  onFlag,
  onDelete,
  showCheckmark = true,
  showDelete = false,
  showEdit = true,
  showFlag = true,
  showRefuse = true,
  isEditing = false,
  isFlagged = false,
  isRefused = false,
  isChecked = false,
  showFavorite = false,
  theme,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{ marginLeft: isEditing ? "16px" : "8px" }}>
      {showCheckmark && (
        <IconButton
          height="30px"
          variant="text"
          onClick={onCheckmark}
          sx={{
            minWidth: "30px",
            marginRight: "4px",
          }}>
          {isChecked ? <Refresh /> : <CheckIcon />}
        </IconButton>
      )}
      {showRefuse && (
        <IconButton
          height="30px"
          variant="text"
          onClick={onRefuse}
          sx={{
            minWidth: "30px",
            marginRight: "4px",
          }}>
          {isRefused ? <Refresh /> : <ClearOutlinedIcon />}
        </IconButton>
      )}
      {showEdit && (
        <IconButton
          height="30px"
          variant="text"
          onClick={isEditing ? onEditCancel : onEdit}
          sx={{
            minWidth: "30px",
            marginRight: "4px",
          }}>
          {isEditing ? <Refresh /> : <EditIcon />}
        </IconButton>
      )}
      {showFlag && (
        <IconButton
          height="30px"
          variant="text"
          onClick={onFlag}
          sx={{
            minWidth: "30px",
            marginRight: "4px",
          }}>
          {isFlagged ? <Refresh /> : <FlagOutlined />}
        </IconButton>
      )}
      {showFavorite && (
        <IconButton
          height="30px"
          variant="text"
          sx={{
            minWidth: "30px",
          }}>
          <Favorite fill={theme.palette.common.blue} />
        </IconButton>
      )}
      {showDelete && (
        <IconButton
          height="30px"
          variant="text"
          onClick={onDelete}
          sx={{
            minWidth: "30px",
          }}>
          <Trash fill={theme.palette.common.blue} />
        </IconButton>
      )}
    </Box>
  );
};
export default ActionItems;
