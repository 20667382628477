import { Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import AISparkImage from "assets/svg/colored/ai_sparkle_black.svg";
import Text from "components/common/Typography/Text";
import { forwardRef, useEffect, useState } from "react";
import Button from "components/common/Button/Button";
import EndVisit from "./EndVisit";
import rightArrow from "assets/svg/light/rightArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  closeEncounter,
  getEncounterById,
  setEncounterStatus,
} from "redux/features/Encounter/EncounterSlice";
import { addLabTest } from "apiClients/labs.api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  PatientDetailHeader,
  PatientDetailWrapper,
} from "components/common/PatientDetailWrapper";
import { EndVisitSuccessModal } from "./endVisitSuccessModal";
import { getLabTests, postLabRequestId } from "redux/features/Labs/labsSlice";
import OutlinedFlagRoundedIcon from "@mui/icons-material/OutlinedFlagRounded";
import { getOrderMedicationsList } from "redux/features/Medications/OrderMedicationsSlice";
import { interimPageFeature, isFeatureEnabled } from "utils/featureFlags";
import EndVisitDetail from "./EndVisitDetail";
import CustomModal from "components/common/Modal/CustomModal";
import InputField from "components/common/FormComponents/InputField";
import ReportAnIssueModal from "components/common/Modal/ReportAnIssueModal";
import { fetchVisitSummaryData } from "apiClients/visitSummary";
import { generateVisitSummaryPDF } from "components/GenerateVisitSummaryPdf";

const useStyles = makeStyles(() => ({
  dashedBorderChip: {
    border: "1px dashed #7119FF !important",
  },
  loader: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    border: "8px solid #7119FF",
    borderBottom: "8px solid transparent",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const EndVisitStepper = forwardRef(
  ({ encounter, sendEncounter, setComponentName }, ref) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { patientId } = useParams();
    const [searchParams] = useSearchParams();
    const reviewLaterParam = searchParams.get("review_later");
    const queryClient = useQueryClient();
    const { flagsData } = useSelector((state) => state.getFlags);

    const isInterimPageEnabled = isFeatureEnabled(
      flagsData,
      interimPageFeature,
    );

    const [open, setOpen] = useState(false);
    const [generatePdf, setGeneratePdf] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [labReqId, setLabReqId] = useState(false);
    const { userId } = useSelector((state) => state.auth);
    const [endVisitActive, setEndVisitActive] = useState(0);
    const [reviewLater, setReviewLater] = useState(false);
    const [showCptReasons, setShowCptReasons] = useState(false);
    const [encounterData, setEncounterData] = useState({
      smart_note_data: {
        diagnosis_data: {},
        labs_to_order: [],
        medications: [],
        imagings: [...(encounter?.smart_note_data?.imaging || [])],
        referrals: [],
        cpt_codes: "",
      },
    });

    // Will be used in the future when interim screen will be added.
    const [userLabEntries, setUserLabEntries] = useState([]);

    const { labRequestData, labsTestData, getLabReqIsLoading } = useSelector(
      (state) => state.labs,
    );
    const { labProvidersDataOptions, labProvidersIsLoading } = useSelector(
      (state) => state.labProviders,
    );
    const {
      intentToCloseEncounter,
      isCloseEncounterPending,
      encounterCloseSuccess,
      encounterSuccess,
      isEncounterLoading,
    } = useSelector((state) => state.encounter);
    const { processingStatus, recordingIsLoading } = useSelector(
      (state) => state.recording,
    );

    const mutation = useMutation({
      mutationFn: async (values) => {
        return addLabTest(patientId, values);
      },
      onError: (error) => {
        console.error("Lab test creation failed:", error); // eslint-disable-line no-console
      },
    });

    const { data: visitSummaryData, isLoading: visitSummaryLoading } = useQuery(
      {
        queryKey: ["visitSummaryData", patientId],
        queryFn: async () =>
          await fetchVisitSummaryData(patientId, encounter?.id),
        enabled: !!patientId && !!encounter?.id && generatePdf,
        retry: false,
      },
    );

    const handleGeneratePDF = async (data) => {
      setGeneratePdf(false);
      try {
        await generateVisitSummaryPDF(data);
      } catch (error) {}
    };

    useEffect(() => {
      visitSummaryData && generatePdf && handleGeneratePDF(visitSummaryData);
    }, [visitSummaryData, generatePdf]);

    useEffect(() => {
      if (labProvidersDataOptions?.length && !labProvidersIsLoading) {
        const labProvider = labProvidersDataOptions?.find(
          (v) => v?.label == "Express Lab",
        );
        //request lab id only if it is not present against the patient and encounter id
        //also check that you are checking against the selected lab provider
        const filterLabReqByEncAndPatient = labRequestData?.find(
          (v) => v?.lab_provider_id == labProvider?.value,
        );
        if (!filterLabReqByEncAndPatient?.id && !getLabReqIsLoading) {
          if (labProvider?.value && patientId) {
            dispatch(
              postLabRequestId(patientId, {
                encounter_id: encounter?.id,
                user_id: userId,
                lab_provider_id: labProvider?.value,
              }),
            );
          }
        } else {
          setLabReqId(filterLabReqByEncAndPatient?.id);
        }
      }
    }, [
      patientId,
      getLabReqIsLoading,
      labProvidersDataOptions,
      labRequestData?.length,
    ]);

    // Poll encounter data every 5 seconds until smart_note_data is available
    useEffect(() => {
      if (!encounter?.smart_note_data && processingStatus !== "FAILED") {
        const timeout = setTimeout(() => {
          dispatch(getEncounterById(encounter.id));
        }, 15000);

        return () => clearTimeout(timeout);
      }
    }, [encounter]);

    function retryEncounter() {
      dispatch(getEncounterById(encounter.id));
    }

    function getIcdCodes(suggestedIcds) {
      if (!suggestedIcds || typeof suggestedIcds !== "object") {
        return [];
      }

      const codes = [];

      Object.keys(suggestedIcds).forEach((category) => {
        const diagnoses = suggestedIcds[category];
        if (Array.isArray(diagnoses)) {
          diagnoses.forEach((diagnosis) => {
            if (diagnosis.icd_10_code) {
              codes.push(diagnosis.icd_10_code);
            }
          });
        }
      });

      return codes;
    }

    function convertDivToCode(text) {
      // Matches 'div' that's not already within code blocks
      // Uses negative lookbehind (?<!`) and negative lookahead (?!`)
      if (text) {
        let check = text.replace(/\bdiv\b/g, "code");
        return check;
      }
    }

    function sendEncounterCloseOrReview(close = false, isReviewLater = false) {
      if (close) {
        dispatch(setEncounterStatus(true));
      } else {
        isReviewLater && setReviewLater(true);
      }
      sendEncounter(
        ref.current?.getPlanNotesInMarkdownFormat(),
        convertDivToCode(
          `${document.getElementsByClassName("editor-input")?.[0]?.innerHTML}`,
        ),
        "o",
        {
          ...encounterData,
          icd_codes: getIcdCodes(
            encounterData?.smart_note_data?.diagnosis_data,
          ),
          cpt_codes:
            typeof encounterData?.smart_note_data?.cpt_codes === "string"
              ? [encounterData.smart_note_data.cpt_codes.replace("manual", "")]
              : encounterData?.smart_note_data?.cpt_codes?.map(
                  ({ cpt_code }) =>
                    cpt_code?.includes("manual")
                      ? cpt_code?.replace("manual", "")
                      : cpt_code,
                ),
        },
      );
    }

    function cancelEndVisit() {
      setComponentName(null);
      window.location.reload();
    }

    useEffect(() => {
      if (encounterSuccess && reviewLater) {
        navigate("/patients");
      }
    }, [encounterSuccess]);

    useEffect(() => {
      encounter?.id && dispatch(getEncounterById(encounter?.id));
    }, [encounter?.id]);

    useEffect(() => {
      if (encounter?.id && patientId) {
        dispatch(getLabTests(patientId, encounter?.id));
        dispatch(getOrderMedicationsList(patientId, encounter?.id));
      }
    }, [patientId, encounter]);

    useEffect(() => {
      encounterCloseSuccess && dispatch(getEncounterById(encounter?.id));
    }, [encounterCloseSuccess]);

    useEffect(() => {
      if (intentToCloseEncounter && !isEncounterLoading && encounterSuccess) {
        const encId = encounter?.id;
        dispatch(closeEncounter(encId));
      }
    }, [intentToCloseEncounter, isEncounterLoading, encounterSuccess]);

    useEffect(() => {
      if (encounterCloseSuccess && intentToCloseEncounter) {
        //open success modal
        setOpen(true);
        dispatch(setEncounterStatus(false));
      }
    }, [intentToCloseEncounter, encounterCloseSuccess]);

    useEffect(() => {
      if (encounter && encounter?.id) {
        setEncounterData(encounter);
      }
    }, [encounter]);

    useEffect(() => {
      labsTestData && setUserLabEntries(labsTestData);
    }, [labsTestData]);

    useEffect(() => {
      if (reviewLaterParam) {
        setEndVisitActive(1);
      }
    }, [reviewLaterParam]);

    if (
      !encounter?.smart_note_data ||
      !encounter?.plan_note ||
      recordingIsLoading
    ) {
      return (
        <Box
          sx={{
            borderRadius: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            height: "72vh",
            marginTop: "16px",
            background: theme.palette.background.paper,
          }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Box sx={{ position: "relative" }}>
              <Box className={classes.loader}></Box>
              <img
                alt="AI Spark"
                src={AISparkImage}
                style={{
                  position: "absolute",
                  top: "31%",
                  left: "29%",
                  height: "34px",
                  width: "34px",
                }}
              />
            </Box>
            <Box sx={{ paddingTop: "24px" }}>
              {processingStatus === "FAILED" ? (
                <Text variant="h2" sx={{ color: theme.palette.error.light }}>
                  Unable To Process Smart Assessment{" "}
                </Text>
              ) : (
                <Text variant="h2">Generating Your Smart Assessment </Text>
              )}
            </Box>
            <Box sx={{ paddingTop: "10px" }}>
              <Text variant="bodyM">
                {processingStatus === "FAILED"
                  ? "Click Retry to re-process the recording."
                  : "Estimated Time: 60 Sec."}{" "}
              </Text>
            </Box>
            <Box sx={{ paddingTop: "10px" }}>
              <Button
                text={processingStatus === "FAILED" ? "Retry" : "Cancel"}
                variant="outlined"
                sx={{
                  border: `1px solid ${theme.palette.common.middleLightGrey}`,
                }}
                onClick={
                  processingStatus === "FAILED"
                    ? retryEncounter
                    : cancelEndVisit
                }
              />
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <PatientDetailWrapper>
        <EndVisitSuccessModal
          open={open}
          isVisitSummaryLoading={visitSummaryLoading}
          printSummary={() => {
            setGeneratePdf(true);
            queryClient.invalidateQueries({
              queryKey: ["visitSummaryData", patientId],
            });
          }}
          onClose={() => {
            setOpen(false);
            navigate("/patients");
          }}
        />
        <ReportAnIssueModal
          isOpen={openFeedback}
          onClose={() => setOpenFeedback(false)}
          title="Feedback"
          showTextAreaLabel={false}
          MainDescription="Leave a comment below to report an issue with the Nephrolytics application. Our support team will work to resolve the issue."
          encounterId={encounter?.id}
        />
        <Box
          sx={{
            height: "68vh",
          }}>
          <PatientDetailHeader title="Finalize Assessment & Plan Note">
            {endVisitActive === 0 ? (
              <>
                <Text variant="h4">Encounter Date:</Text>
                <Text sx={{ margin: "0 12px" }} variant="h3">
                  {encounter?.datetime
                    ? moment(encounter?.datetime).format("MM-DD-YYYY")
                    : "---"}
                </Text>
                <Button
                  text={isInterimPageEnabled ? "Accept" : "Next"}
                  rightSide
                  sx={{ padding: "5px" }}
                  iconDetails={{ allowIcon: true, icon: rightArrow }}
                  onClick={() => {
                    setEndVisitActive(1);
                  }}
                />
              </>
            ) : (
              <>
                <Button
                  text="Review Later"
                  variant="outlined"
                  sx={{ padding: "5px 10px", marginLeft: "10px" }}
                  isLoading={isEncounterLoading}
                  onClick={() => sendEncounterCloseOrReview(false, true)}
                />
                <Button
                  isLoading={isCloseEncounterPending}
                  sx={{ padding: "5px 15px", marginLeft: "10px" }}
                  text="Sign Notes & End Visit"
                  onClick={() => sendEncounterCloseOrReview(true)}
                />
              </>
            )}
          </PatientDetailHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "24px 24px",
            }}>
            <Box sx={{ width: "100%" }}>
              {isInterimPageEnabled ? (
                endVisitActive === 0 ? (
                  <EndVisitDetail
                    encounterData={encounterData}
                    setUserLabEntries={setUserLabEntries}
                    setEncounterData={setEncounterData}
                    encounter={encounter}
                  />
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 0,
                        position: "relative",
                        zIndex: 2,
                      }}>
                      <Text color={"grey"} sx={{ fontWeight: "700" }}>
                        NOTES
                      </Text>
                      <Box
                        sx={{
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          margin: "-0px -8px",
                        }}
                        onClick={() => setOpenFeedback(!openFeedback)}>
                        <OutlinedFlagRoundedIcon
                          sx={{
                            color: theme.palette.common.blue,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      Box
                      sx={{
                        position: "relative",
                        zIndex: 1,
                      }}>
                      <EndVisit
                        ref={ref}
                        encounter={encounter}
                        encounterData={encounterData}
                        userLabEntries={userLabEntries}
                      />
                    </Box>
                  </>
                )
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0,
                      position: "relative",
                      zIndex: 2,
                    }}>
                    <Text color={"grey"} sx={{ fontWeight: "700" }}>
                      NOTES
                    </Text>
                    <Box
                      sx={{
                        padding: "8px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        margin: "-0px -8px",
                      }}
                      onClick={() => setOpenFeedback(!openFeedback)}>
                      <OutlinedFlagRoundedIcon
                        sx={{
                          color: theme.palette.common.blue,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    Box
                    sx={{
                      position: "relative",
                      zIndex: 1,
                    }}>
                    <EndVisit encounter={encounter} ref={ref} />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </PatientDetailWrapper>
    );
  },
);

export default EndVisitStepper;
