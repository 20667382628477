import MainLayout from "Layouts/AppContainer/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import CustomModal from "components/common/Modal/CustomModal";
import Button from "components/common/Button/Button";
import { Box } from "@mui/material";
import { clearUserLoggedIn } from "redux/features/Auth/loginSlice";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { loggedIn, userWasLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!loggedIn) {
      if (userWasLoggedIn) {
        setShowModal(true);
      } else {
        dispatch(clearUserLoggedIn());
        navigate("/login");
      }
    }
  }, [loggedIn, userWasLoggedIn, navigate]);

  const handleOkClick = () => {
    dispatch(clearUserLoggedIn());
    setShowModal(false);
    navigate("/login");
  };

  return (
    <>
      <CustomModal
        open={showModal}
        title="Session Expired"
        maxWidth="sm"
        fade={true}
        showCloseIcon={false}
        actions={[
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleOkClick} text={"Ok"} />
          </Box>,
        ]}
        sxDialog={{
          "& .MuiDialog-paper": {
            maxWidth: "400px",
          },
        }}
        sxDialogContent={{
          padding: "0px",
          minWidth: "auto",
        }}>
        <Box sx={{ padding: "15px" }}>
          Your session has ended. Please login again.
        </Box>
      </CustomModal>

      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  );
};

export default PrivateRoute;
