import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import { IconButton } from "components/common/Button/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "components/common/FormComponents/Form";
import { Trash } from "components/common/SVG/Trash";
import EditIcon from "@mui/icons-material/EditOutlined";
import SelectField from "components/common/FormComponents/SelectField";
import { fetchReferralCodes } from "apiClients/encounters";
import ItemDisplayWrapper from "./ItemDisplayWrapper.js";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer.js";
import InputField from "components/common/FormComponents/InputField.js";

const ReferralDisplay = ({
  referral,
  aiGenerated,
  onDelete,
  setStatus,
  showStatus = false,
  note,
  sx,
  setShowNotesInput,
}) => {
  return (
    <ItemDisplayWrapper
      sx={sx}
      aiGenerated={aiGenerated}
      showEdit={false}
      showFavorite={false}
      onDelete={onDelete}
      setStatus={setStatus}
      canBeDeleted={referral?.canBeDeleted}
      title={`${referral?.description ? referral?.description : referral?.action}`}>
      {note && showStatus && (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Text
            fontWeight={500}
            variant="caption"
            color="textSecondary"
            sx={{ marginTop: "4px" }}
            whiteSpace="normal">
            {note}
          </Text>
          <Box
            onClick={() => setShowNotesInput(true)}
            sx={{
              marginLeft: "8px",
              height: "10px",
              width: "10px",
            }}>
            <EditIcon sx={{ height: "20px", width: "20px" }} />
          </Box>
        </Box>
      )}
    </ItemDisplayWrapper>
  );
};

const ReferralForm = ({
  onSubmit,
  onCancel,
  referrals,
  aiGenerated = false,
  initialReferral = null,
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [note, setNote] = useState("");

  const handleSearch = async (value) => {
    if (!value) return;
    // Store the query for future "load more" calls
    setSearchQuery(value);
    setIsLoading(true);
    try {
      const data = await fetchReferralCodes({ query: value, page: 1 });
      const mappedResults = data?.results
        .map((item) => ({
          label: item.loinc_code,
          value: item.description,
          description: item.description,
        }))
        .filter((item) => !referrals?.some((d) => d.loinc_code === item.value));
      setOptions(mappedResults);
      setPage(1);
      // Assume if results are less than the page size (e.g., 20) then no more pages
      setHasMore(data?.results?.length === 20);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMore = async () => {
    if (!searchQuery || !hasMore) return;
    setIsLoading(true);
    try {
      const nextPage = page + 1;
      const data = await fetchReferralCodes({
        query: searchQuery,
        page: nextPage,
      });
      const mappedResults = data?.results
        .map((item) => ({
          label: item.loinc_code,
          value: item.description,
          description: item.description,
        }))
        .filter((item) => !referrals?.some((d) => d.loinc_code === item.value));
      // Append new options to the existing options
      setOptions((prevOptions) => [...prevOptions, ...mappedResults]);
      setPage(nextPage);
      // Update hasMore based on if we received a full page of data
      setHasMore(data?.results?.length === 20);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const infiniteScrollDetails = {
    hasMoreToFetch: hasMore,
    fetchMore,
  };

  const showSubmitButton = selectedReferral;

  const handleSubmit = () => {
    if (!selectedReferral) return;
    onSubmit({
      description: selectedReferral.value,
      loinc_code: selectedReferral.label,
      canBeDeleted: !aiGenerated,
      aiGenerated,
      status: "Accepted",
      action: selectedReferral.value,
      note, // Include note in the referral data
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ marginTop: "12px", width: "100%" }}>
      <Box display="flex" flexDirection="row">
        <EditIcon
          sx={{ color: theme.palette.common.purple, margin: "4px 4px 0 0" }}
        />
        <Form>
          <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
            <Box display="flex" flexDirection="row">
              <Box sx={{ marginRight: "8px" }}>
                <SelectField
                  options={options}
                  loading={isLoading}
                  openOnFocus={false}
                  disabled={aiGenerated}
                  // Use debounced onSearch to reduce API calls
                  onSearch={debounce((value) => {
                    handleSearch(value);
                  }, DEBOUNCE_DELAY)}
                  value={selectedReferral}
                  onClear={() => setSelectedReferral(null)}
                  onChange={(_, v) => setSelectedReferral(v)}
                  placeholder="Search Referral - Or - LIONIC Code"
                  showMoreInLabel={{ show: true, value: "value" }}
                  extendedSearchOptions={["value"]}
                  // Pass infiniteScrollDetails to support scrolling
                  infiniteScrollDetails={infiniteScrollDetails}
                  sx={{ width: initialReferral ? "322px" : "450px" }}
                />
              </Box>
              {showSubmitButton && (
                <IconButton
                  height="40px"
                  onClick={handleSubmit}
                  sx={{ minWidth: "40px" }}>
                  <CheckIcon sx={{ height: "20px", width: "20px" }} />
                </IconButton>
              )}
              <IconButton
                onClick={onCancel}
                sx={{ marginLeft: "8px", height: "40px", width: "40px" }}>
                <Trash />
              </IconButton>
            </Box>
            {/* New Note InputField */}
            <Box sx={{ marginTop: "8px" }}>
              <InputField
                inputLabel="Enter note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                sx={{ width: initialReferral ? "322px" : "450px" }}
              />
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export const Referral = ({
  aiGenerated = false,
  setReferrals,
  referrals,
  onDelete = () => {},
  onAdd,
  referral,
  createNewReferral,
  setShowInput = () => {},
  sx,
  editNote,
}) => {
  const [note, setNote] = useState(referral?.note || "");
  const [showNotesInput, setShowNotesInput] = useState(false);

  const handleDelete = () => {
    onDelete(referral.loinc_code);
    if (!referral?.aiGenerated) {
      setReferrals(
        referrals?.filter((d) => d.loinc_code !== referral?.loinc_code),
      );
    }
    setShowInput(false);
  };

  const setStatus = (feedback) => {
    if (feedback === "Flagged") setShowNotesInput(true);
    else {
      setShowNotesInput(false);
      setNote("");
    }

    const updatedReferral = {
      ...referral,
      status: feedback,
    };
    setReferrals(
      referrals.map((d) =>
        d.loinc_code === referral.loinc_code ? updatedReferral : d,
      ),
    );
    if (aiGenerated) editNote(referral.action, null, feedback);
  };

  const handleSubmit = (referralData) => {
    setNote(referralData?.note);
    setReferrals([...(referrals || []), referralData]);
    onAdd(referralData);
    setShowInput(false);
  };

  if (createNewReferral) {
    return (
      <ReferralForm
        onCancel={() => {
          setShowInput(false);
        }}
        note={note}
        setNote={setNote}
        referrals={referrals}
        onSubmit={handleSubmit}
        aiGenerated={aiGenerated}
        showNotesInput={showNotesInput}
        setShowNotesInput={setShowNotesInput}
      />
    );
  }

  return (
    <>
      <ReferralDisplay
        sx={sx}
        setStatus={setStatus}
        referral={referral}
        aiGenerated={aiGenerated}
        onDelete={handleDelete}
        note={note}
        showStatus={!showNotesInput}
        setShowNotesInput={setShowNotesInput}
      />

      {showNotesInput && (
        <Box sx={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
          <InputField
            defaultValue={note}
            sx={{ width: "100%" }}
            inputLabel={"Enter note"}
            onChange={(e) => setNote(e.target.value)}
          />
          <IconButton
            height="40px"
            onClick={() => {
              setShowNotesInput(false);
              editNote(
                aiGenerated ? referral?.action : referral?.description,
                note,
                null,
              );
            }}
            sx={{ minWidth: "40px", marginLeft: "8px" }}>
            <CheckIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </Box>
      )}
    </>
  );
};
