import LoginWrapper from "./LoginWrapper";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../../../redux/features/Auth/loginSlice";
import { isFeatureEnabled, oidcFeature } from "../../../../utils/featureFlags";

const LoginFormOidc = () => {
  const { loggedIn, firstTimeLogin } = useSelector((state) => state.auth);
  const { flagsData } = useSelector((state) => state.getFlags);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // It may seem strange to use an old-fashioned useState here, but it is being used as a simple gate to dispatching the getCurrentUser
  // action. It should not be persisted with the rest of application state.
  // It is needed along with checking feature flag state to ensure we are comparing an accurate value of the OIDC flag immediately
  // before firing the action.
  const [readyToLogin, setReadyToLogin] = useState(false);

  useEffect(() => {
    // If the user is already logged in, redirect to the appropriate page immediately
    if (loggedIn) {
      firstTimeLogin ? navigate("/settings?tab=0") : navigate("/appointments");
    } else {
      // If we are notlogged in, wait a moment before allowing the actual login comparison to occur.
      // This prevents trying to log back in after we have set the user state to logged out but the
      // logout redirect page is still loading,
      setTimeout(() => {
        setReadyToLogin(true);
      }, 1500);
    }
  }, [loggedIn, firstTimeLogin, navigate, dispatch]);

  // Perform the login by dispatching the getCurrentUser action. This is gated on both the OIDC feature
  // flag being enabled and the readyToLogin state being true. This ensures that it is not run
  // unintentionally when a stale feature flag setting is used when the component is first mounted.
  useEffect(() => {
    if (isFeatureEnabled(flagsData, oidcFeature) && readyToLogin) {
      dispatch(getCurrentUser());
      setReadyToLogin(false);
    }
  }, [dispatch, flagsData, readyToLogin]);

  return (
    <LoginWrapper>
      <Box sx={{ maxWidth: "100%", width: "300px", marginTop: "33px" }}>
        <p>Logging in user...</p>
      </Box>
    </LoginWrapper>
  );
};

export default LoginFormOidc;
