import LoginForm from "./components/LoginForm";
import { useSelector } from "react-redux";
import { isFeatureEnabled, oidcFeature } from "../../../utils/featureFlags";
import LoginFormOidc from "./components/loginFormOidc";
import { useState } from "react";
import { useEffect } from "react";

const Login = () => {
  const { flagsData } = useSelector((state) => state.getFlags);
  const [oidcEnabled, setOidcEnabled] = useState(false);

  useEffect(() => {
    setOidcEnabled(isFeatureEnabled(flagsData, oidcFeature));
  }, [flagsData]);

  return oidcEnabled ? <LoginFormOidc /> : <LoginForm />;
};

export default Login;
